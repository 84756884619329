@import '~sass-rem';
@import 'common/styles/colors.scss';

.draggableItemWrapper {
    position: relative;
    display: flex;
    padding: rem(0) rem(45px);
    background-color: 'lightblue';
    &:active {
        opacity: 0.5;
    }
}

.dragIcon {
    position: absolute;
    left: rem(10px);
    top: rem(-4px);
    font-size: rem(30px);
    opacity: 0.75;
    cursor: grab;
}

.draggableItem {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0px rem(5px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
