@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.toolbar {
    background-color: $tooltip-header;
}

.container {
    min-height: calc(100vh - rem(64px));
    min-width: 900px;
    padding: rem(60px) rem(80px) rem(100px);
    @include desktop {
        width: calc(100% - 800px);
    }
}

.leftColumn {
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 40%;
}

.select {
    margin-bottom: rem(20px);
}

.rightColumn {
    width: 60%;
    overflow-y: auto;
    overflow-x: hidden;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    .inputWrapper {
        flex-direction: column;
        width: calc(50% - 10px);
    }
}

.subContainer {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.uploadCover {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: rem(20px);
    > label {
        position: relative;
        display: block;
        margin-right: rem(20px);
    }
    .fileName {
        display: inline-block;
    }
}

.noSelectedStyng {
    padding: 20px;
    text-align: center;
    font-size: 14px;
}

.uploadImage {
    width: calc(50% - 10px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img {
        max-height: 300px;
        margin-right: rem(20px);
        object-fit: cover;
    }
}

.errHolder {
    display: flex;
    flex-direction: column;
}

.errMessage {
    margin: 4px 0px 0px 10px;
    color: $error;
    font-size: 12px;
}

.submitContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: calc(100% - 15rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    padding: rem(20px) rem(18px);
    background-color: $white;
}
