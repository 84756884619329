@import '~sass-rem';
@import 'common/styles/colors.scss';
@import 'common/styles/mixins.scss';

.backdropDiv {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 51;
}

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 600px;
    background-color: #fff;
    border: 1px solid lightgray;
    box-shadow: 24px;
    border-radius: 5px;
    padding: 20px;
    .header {
        width: 100%;
        font-weight: 600;
        margin-bottom: rem(18px);
        border-bottom: 1px solid lightgray;
    }
    .noDataWrapper {
        margin: rem(60px) 0;
        text-align: center;
    }
}

.submitContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    border-top: 1px solid lightgray;
    padding-top: rem(18px);
    margin-top: rem(20px);
    button {
        margin-left: rem(15px);
    }
    .errorInfoBox {
        padding-bottom: rem(18px);
        width: 100%;
        flex-shrink: 0;
        text-align: center;
        color: $error;
    }
}

.select {
    margin-bottom: rem(30px);
}
