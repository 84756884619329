@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.styngFilter {
    height: 250px;
    padding-right: rem(10px);
    overflow-y: auto;
    scrollbar-gutter: stable;
}

.styngNoResult {
    font-size: 14px;
    padding: 8px;
}
