@import '~sass-rem';
@import 'common/styles/colors.scss';
@import 'common/styles/mixins.scss';

.rowWrapper {
    margin-bottom: rem(18px);
}

.rowContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    .textField {
        margin: 0;
        width: calc(50% - 25px);
    }
}

.addNewwWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .textField {
        margin: 0;
        width: calc(50% - 50px);
    }
}

.deleteIcon {
    font-size: 30px;
    cursor: pointer;
    &:hover {
        color: rgb(165, 19, 19);
    }
}
