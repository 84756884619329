@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 200px);
    min-height: 700px;
    background-color: #fff;
    border: 1px solid lightgray;
    box-shadow: 24px;
    border-radius: 5px;
    padding: 20px;
    .header {
        width: 100%;
        font-weight: 600;
        margin-bottom: rem(18px);
        border-bottom: 1px solid lightgray;
        @include desktop {
            flex-direction: row;
        }
    }
    .innerContainer {
        display: flex;
        overflow: auto;
        padding-bottom: rem(20px);
        margin-bottom: auto;
    }
    .searchWrapper {
        position: relative;
        width: 100%;
        padding-right: rem(20px);
        padding-top: rem(10px);
    }
    .customError {
        display: block;
        font-size: 0.75rem;
        line-height: 1.66;
        letter-spacing: 0.03333em;
        text-align: left;
        margin: 3px 14px 0 14px;
        color: $error;
    }
    .audioPreview {
        margin-bottom: rem(20px);
    }
    @include desktop {
        width: calc(100% - 600px);
    }
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: rem(10px);
}

.submitContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid lightgray;
    padding-top: rem(18px);
    button {
        margin-left: rem(15px);
    }
}

.uploadCover {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: rem(20px);

    > label {
        position: relative;
        display: block;
        margin-right: rem(20px);
    }

    .fileName {
        display: inline-block;
    }
}

.uploadImage {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: rem(20px) 0 rem(12px);

    img {
        max-height: 300px;
        margin-right: rem(20px);

        object-fit: cover;
    }
}
