@import '~sass-rem';
@import 'common/styles/colors.scss';

.showDropAreaWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed darken(#000, 20%);
    border-radius: 4px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.hideDropAreaWrapper {
    background-color: white;
    opacity: 0;
    border: 2px dashed transparent;
    border-radius: 4px;
}
