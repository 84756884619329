@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.playlistEdit {
    .toolbar {
        color: $white;
        background-color: $tooltip-header;
    }
    .pageTitle {
        margin: 0px 0px 0px 16px;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 0.0075em;
    }
    .generalInfo {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding: 1.25rem;
    }
    .header {
        width: 100%;
        font-weight: 600;
        margin: rem(10px) 0;
        &:first-of-type {
            margin-top: 0;
        }
    }
    .textField {
        width: 40%;
        margin-right: 20px;
    }
    .autocompleteWrapper {
        display: flex;
        flex-direction: column;
        padding-bottom: rem(20px);
    }
    .autocompleteField {
        width: 500px;
    }
    .autocompleteFieldValues {
        padding: rem(10px) 0;
    }
    .autocompleteFieldValue {
        margin: rem(5px);
    }
    .saveButton {
        display: flex;
        flex-shrink: 0;
        text-transform: uppercase;
    }
}

.container {
    display: flex;
    flex-direction: column;
    position: fixed;
    overscroll-behavior: none;
    top: 0;
    left: 0;
    overflow: auto;
    width: 100% !important;
    height: 100%;
    background-color: #fff;
    border: 1px solid lightgray;
    z-index: 100;

    .header {
        width: 100%;
        font-weight: 600;
        margin-bottom: rem(18px);
        border-bottom: 1px solid lightgray;

        @include desktop {
            flex-direction: row;
        }
    }

    .innerContainer {
        display: flex;
        overflow: auto;
        padding-bottom: rem(20px);
        margin-bottom: auto;
    }

    .searchWrapper {
        position: relative;
        width: 100%;
        padding-right: rem(20px);
        padding-top: rem(10px);
    }

    .customError {
        display: block;
        font-size: 0.75rem;
        line-height: 1.66;
        letter-spacing: 0.03333em;
        text-align: left;
        margin: 3px 14px 0 14px;
        color: $error;
    }

    @include desktop {
        width: calc(100% - 600px);
    }
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: rem(10px);
}

.submitContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: calc(100% - 15rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    padding: rem(20px) rem(18px);
    background-color: $white;
}

.uploadCover {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: rem(20px);

    > label {
        position: relative;
        display: block;
        margin-right: rem(20px);
    }

    .fileName {
        display: inline-block;
    }
}

.uploadImage {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: rem(20px) 0 rem(12px);

    img {
        max-height: 300px;
        margin-right: rem(20px);
        object-fit: cover;
    }
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: rem(20px);
    width: 100%;

    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        max-width: rem(344px);
    }
}

.buttonsHolder {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    padding: rem(20px);
    margin: rem(10px 25px 0px 0px);

    :nth-child(1) {
        margin-right: rem(10px);
    }
}

.emptyResponse {
    width: 100%;
    padding: rem(20px);
    text-align: center;
    font-weight: 600;
}
