@import '~sass-rem';
@import 'common/styles/colors.scss';

.droppableContainerWrapper {
    position: relative;
    margin: rem(16px) rem(16px) rem(30px);
    min-height: '200px';
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.name {
    position: absolute;
    top: -15px;
    left: 15px;
    padding: rem(0px) rem(16px);
    border: 1px solid lightgray;
    background-color: white;
}

.columnsWrapper {
    display: flex;
    padding: rem(16px) rem(45px);
    border-bottom: 1px solid lightgray;
    font-weight: 600;
}

.column {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0px rem(5px);
}

.showDropAreaPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    border: 2px dashed transparent;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.hideDropAreaPlaceholder {
    display: none;
}
